import { gql } from 'graphql-request';

export const UPDATE_SHIPMENT_EVENTS = gql`
  mutation updateShipmentEvents($input: UpdateShipmentEventsInput!) {
    updateShipmentEvents(input: $input) {
      shipmentEvents {
        id
      }
    }
  }
`;
