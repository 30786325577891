import * as FullStory from '@fullstory/browser';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { useQueryParams } from 'core/hooks';
import { UPDATE_SHIPMENT_EVENTS } from 'core/graphql';
import { getShipmentsRequest } from 'core/graphql/queries/shipments';
import { client } from 'core/utils/axios.config';
import { gql } from 'graphql-request';

const isNotificationsConfigHidden =
  process.env.REACT_APP_HIDE_NOTIFICATIONS_CONFIG_FROM_TRACK_AND_TRACE ===
  'true';

const REJECTED_STATUS = 'rejected';

export const useGetShipments = (
  { after, byRepId, companyId, first, id, state = 'active' },
  queryOptions
) =>
  useQuery(
    ['shipments', state, id, first, after, byRepId, companyId],
    () =>
      getShipmentsRequest({
        after,
        byRepId,
        companyId,
        first,
        id,
        state,
      }),
    queryOptions
  );

const PLACE_FIELDS_FRAGMENT = gql`
  fragment placeFields on Place {
    zipCode {
      name
      parents {
        country
        city
        state
      }
    }
  }
`;

const ATTACHMENT_FRAGMENT = gql`
  fragment attachmentFields on Attachment {
    fileName
    url
    createdAt
  }
`;

const SHIPMENT_EVENT_FRAGMENT = gql`
  fragment shipmentEventFields on ShipmentEvent {
    id
    estimatedDatetime
    actualDatetime
    type
    ${isNotificationsConfigHidden ? '' : 'notifyCustomer'}
    shipmentEventIssues {
      id
      reason
      internalNote
      noteEnglish
      noteSpanish
      createdAt
      updatedAt
      reporter {
        name
      }
      resolvedAt
      resolver {
        name
      }
    }
  }
`;

/**
 * @param { string | number } shipmentId
 * @param { Object[] } attributes Array of fields to update in a Shipment Event, alongisde its id
 */
export const updateShipmentEvents = (shipmentId, attributes) =>
  client
    .gql({
      query: UPDATE_SHIPMENT_EVENTS,
      variables: {
        input: {
          shipmentId,
          attributes,
        },
      },
    })
    .then(r => r?.data?.data ?? {});

const createShipment = body => client.post('/edge/shipments.json', body);

export const useCreateShipment = onSuccessCallback => {
  const queryClient = useQueryClient();

  return useMutation(body => createShipment(body), {
    onSuccess: createdShipment => {
      onSuccessCallback?.(createdShipment);

      FullStory.event('Created a shipment', {
        createdShipment,
      });

      return queryClient.invalidateQueries(['shipments']);
    },
  });
};

// GQL
const GET_SHIPMENT_BY_ID_QUERY = gql`
  query getShipments($shipmentId: ID) {
    shipments(id: $shipmentId) {
      nodes {
        id
        trialPeriod
        customerReference
        documentsRequestedAt
        folioNumber
        state
        company {
          legalName
        }
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        attachments {
          id
          category
          name
          createdAt
          document {
            ...attachmentFields
          }
        }
        documents {
          ...attachmentFields
        }
        currentShipmentEvent {
          ...shipmentEventFields
        }
        shipmentEvents {
          nodes {
            ...shipmentEventFields
          }
        }
        customerInvoice {
          ...attachmentFields
        }
        legs {
          id
          carrier {
            name
            scac
            caat
          }
          origin {
            ...placeFields
            name
            addressLine1
            contactName
            contactTelephone
          }
          destination {
            ...placeFields
            name
            addressLine1
            contactName
            contactTelephone
          }
          driverName
          trailerNumber
          trailerPlateNumber
          trailerPlateState
          truckNumber
          truckPlateNumber
          truckPlateState
          position
          pod {
            ...attachmentFields
          }
          receivableLineItems {
            edges {
              node {
                id
                invoiceXml {
                  ...attachmentFields
                }
                invoice {
                  ...attachmentFields
                }
              }
            }
          }
          pita {
            ...attachmentFields
          }
          doda {
            ...attachmentFields
          }
          manifest {
            ...attachmentFields
          }
          cartaPorte {
            xmlDocument {
              ...attachmentFields
            }
            fromClient {
              ...attachmentFields
            }
            fromCarrier {
              ...attachmentFields
            }
          }
        }
      }
    }
  }

  ${SHIPMENT_EVENT_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${PLACE_FIELDS_FRAGMENT}
`;

export const getShipmentByID = shipmentId =>
  client
    .gql({
      query: GET_SHIPMENT_BY_ID_QUERY,
      variables: {
        shipmentId,
      },
    })
    .then(r => r?.data?.data?.shipments?.nodes?.[0] ?? null);

export const useShipmentByID = (shipmentId, queryConfig = {}) => {
  return useQuery(['shipment', shipmentId], () => getShipmentByID(shipmentId), {
    ...queryConfig,
  });
};

const updateEventsWithDelay = async (shipmentId, data) => {
  const [result] = await Promise.allSettled([
    updateShipmentEvents(shipmentId, data),
    new Promise(resolve => setTimeout(resolve, 600)),
  ]);

  if (result.status === REJECTED_STATUS) {
    throw result.reason;
  }

  return result?.value;
};

export const useUpdateShipmentEventByQueryParam = (queryConfig = {}) => {
  const { shipmentId } = useQueryParams();
  const queryClient = useQueryClient();
  const { isNewEstimatedEventsActive } = queryConfig;

  return useMutation(
    !!isNewEstimatedEventsActive
      ? data => updateEventsWithDelay(shipmentId, data)
      : data => updateShipmentEvents(shipmentId, data),
    {
      ...queryConfig,
      onSuccess: (...args) => {
        queryClient.invalidateQueries(['shipment', shipmentId]);
        queryClient.invalidateQueries('shipments');

        queryConfig?.onSuccess?.(...args);
      },
    }
  );
};
